import Link from 'next/link';
import React, { FC } from 'react';

import clsx from 'clsx';
import { motion } from 'framer-motion';

import Preloader from './preloader';
import { ButtonSize, PropsButton, ButtonVariant } from './type';

import styles from './button.module.scss';

const Button: FC<PropsButton> = ({
  type,
  children,
  variant = ButtonVariant.contained,
  href,
  size = ButtonSize.m,
  onClick,
  className,
  disabled = false,
  loading = false,
  leftIcon,
  rightIcon,
}) => {
  if (href) {
    return (
      <Link href={href}>
        <motion.div
          whileTap={{ y: 3 }}
          onClick={onClick}
          className={clsx(
            styles.button,
            { [styles.buttonSizeS]: size === 's' },
            [className],
          )}>
          {children}
        </motion.div>
      </Link>
    );
  }

  const render = (): JSX.Element => {
    if (loading) {
      return <Preloader />;
    }

    return (
      <>
        {leftIcon}
        <p className={styles.buttonText}>{children}</p>
        {rightIcon}
      </>
    );
  };

  return (
    <motion.button
      whileTap={{ y: 3 }}
      type={type}
      className={clsx(styles.button, [className], {
        [styles.buttonSizeS]: size === 's',
        [styles.outlined]: variant === 'outlined',
      })}
      disabled={disabled || loading}
      onClick={onClick}>
      {render()}
    </motion.button>
  );
};

export default Button;
