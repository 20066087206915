import { Nullable } from 'types/common';
import * as yup from 'yup';

import {
  RegistrationInputNamesEnum,
  ResetPasswordType,
  UpdatePasswordValues,
} from 'types/registration';
import { UserInformation, UserPasswordEnum } from 'types/user';
import { getAge } from './helper';

export type AuthorizationValues = {
  email: string;
  password: string;
};

const ROCHE = 'roche';

export type ResetPasswordValues = Pick<AuthorizationValues, 'email'>;

const RU_LETTERS = /^[А-ЯЁа-яё]+$/;
const RU_EN_LETTERS = /^[А-ЯЁа-яёA-z\s]+$/;
const EN_LETTERS_AND_DIGITS = /^[A-z0-9_.-]+$/;

export const PASSWORD_RESTRICTIONS = {
  MIN_LENGTH: 8,
  MAX_LENGTH: 15,
};

const STRING_RESTRICTION = {
  NAME_MAX_LENGTH: 15,
  POSITION_MAX_LENGTH: 20,
};

const MIN_AGE = 18;

const PASSWORD_MESSAGES = {
  NOT_MATCH: 'Пароли не совпадают',
  REQUIRED: 'Пароли должны совпадать',
  REQUIRED_MIN_LENGTH: `Должно быть минимум ${PASSWORD_RESTRICTIONS.MIN_LENGTH} символов`,
  REQUIRED_MAX_LENGTH: `Не должен превышать ${PASSWORD_RESTRICTIONS.MAX_LENGTH} символов`,
  INVALID: 'Используйте латиницу и цифры',
};

const ERROR_MESSAGES = {
  REQUIRED: 'Обязательное поле для заполнения',
  GET_REQUIRED_DOMAINS: (domain: string): string =>
    `Пожалуйста, введите свою почту @${domain}`,
  INVALID_NAME: 'Введите ФИО на русском языке',
  INVALID_POSITION: 'Может содержать только буквы',
  INVALID_DOB: 'Вы должны быть совершеннолетним',
  INVALID_EMAIL: 'Неверная почта',
  INVALID_POSITION_LENGTH: `Не должно превышать ${STRING_RESTRICTION.POSITION_MAX_LENGTH} символов`,
  INVALID_NAME_LENGTH: `Не должно превышать ${STRING_RESTRICTION.NAME_MAX_LENGTH} символов`,
};

const passwordSchema = yup
  .string()
  .matches(EN_LETTERS_AND_DIGITS, {
    excludeEmptyString: true,
    message: PASSWORD_MESSAGES.INVALID,
  })
  .required(ERROR_MESSAGES.REQUIRED)
  .min(PASSWORD_RESTRICTIONS.MIN_LENGTH, PASSWORD_MESSAGES.REQUIRED_MIN_LENGTH)
  .max(PASSWORD_RESTRICTIONS.MAX_LENGTH, PASSWORD_MESSAGES.REQUIRED_MAX_LENGTH);

const requiredSchema = yup.string().required(ERROR_MESSAGES.REQUIRED);

const nameSchema = yup
  .string()
  .matches(RU_LETTERS, {
    excludeEmptyString: true,
    message: ERROR_MESSAGES.INVALID_NAME,
  })
  .max(
    STRING_RESTRICTION.POSITION_MAX_LENGTH,
    ERROR_MESSAGES.INVALID_NAME_LENGTH,
  )
  .required(ERROR_MESSAGES.REQUIRED);

const getEmailSchema = (domains: string[]) =>
  yup
    .string()
    .required(ERROR_MESSAGES.REQUIRED)
    .email(ERROR_MESSAGES.INVALID_EMAIL)
    .test(
      'test-domain',
      ERROR_MESSAGES.GET_REQUIRED_DOMAINS(domains[0]),
      (value) =>
        domains.some(
          (domain) => !!value && value.toLowerCase().endsWith(domain),
        ),
    );

const confirmPasswordSchema = yup
  .string()
  .required(PASSWORD_MESSAGES.REQUIRED)
  .oneOf(
    [
      yup.ref(RegistrationInputNamesEnum.password),
      yup.ref(UserPasswordEnum.newPassword),
    ],
    PASSWORD_MESSAGES.NOT_MATCH,
  );

export const updateInformationSchema: yup.SchemaOf<
  Omit<UserInformation, 'department'>
> = yup.object({
  firstName: nameSchema,
  secondName: nameSchema,
  patronymic: nameSchema,
  location: requiredSchema,
  position: yup
    .string()
    .trim()
    .matches(RU_EN_LETTERS, {
      excludeEmptyString: true,
      message: ERROR_MESSAGES.INVALID_POSITION,
    })
    .max(
      STRING_RESTRICTION.POSITION_MAX_LENGTH,
      ERROR_MESSAGES.INVALID_POSITION_LENGTH,
    )
    .required(ERROR_MESSAGES.REQUIRED),
});

export const getRegistrationSchema = (
  domains: string[],
  unrequiredFields?: Nullable<RegistrationInputNamesEnum[]>,
) => {
  const registrationSchemaObject = yup.object({
    firstName: nameSchema,
    secondName: nameSchema,
    department: requiredSchema,
    location: requiredSchema,
    birthDate: yup
      .string()
      .nullable()
      .test(
        'test-date',
        ERROR_MESSAGES.INVALID_DOB,
        (value) => !!value && getAge(value) >= MIN_AGE,
      )
      .required(ERROR_MESSAGES.REQUIRED),
    position: yup
      .string()
      .trim()
      .matches(RU_EN_LETTERS, {
        excludeEmptyString: true,
        message: ERROR_MESSAGES.INVALID_POSITION,
      })
      .max(
        STRING_RESTRICTION.POSITION_MAX_LENGTH,
        ERROR_MESSAGES.INVALID_POSITION_LENGTH,
      )
      .required(ERROR_MESSAGES.REQUIRED),
    email: getEmailSchema(domains),
    password: passwordSchema,
    confirmPassword: confirmPasswordSchema,
    termsOfService: yup.boolean().required().oneOf([true]),
    privacyPolicy: yup.boolean().required().oneOf([true]),
  });

  return unrequiredFields && unrequiredFields?.length > 1
    ? registrationSchemaObject.omit(unrequiredFields)
    : registrationSchemaObject;
};

export const updatePasswordSchema: yup.SchemaOf<UpdatePasswordValues> =
  yup.object({
    oldPassword: requiredSchema,
    newPassword: passwordSchema,
    repeat_password: confirmPasswordSchema,
  });

export const getAuthorizationSchema = (
  domains: string[],
  name: Nullable<string>,
): yup.SchemaOf<AuthorizationValues> =>
  yup.object({
    email:
      name?.toLowerCase() === ROCHE ? requiredSchema : getEmailSchema(domains),
    password: requiredSchema,
  });

export const getResetPasswordSchema = (
  domains: string[],
): yup.SchemaOf<ResetPasswordValues> =>
  yup.object({
    email: getEmailSchema(domains),
  });

export const schemaDropPassword: yup.SchemaOf<ResetPasswordType> = yup.object({
  password: passwordSchema,
  confirmPassword: confirmPasswordSchema,
});
