import React, { FC, FormEventHandler, ReactElement } from 'react';

interface FormProps {
  onSubmit: FormEventHandler<HTMLFormElement>;
  className?: string;
  children: ReactElement[] | ReactElement;
}

const Form: FC<FormProps> = ({
  onSubmit,
  className = '',
  children,
}: FormProps) => (
  <form className={className} onSubmit={onSubmit} noValidate>
    {children}
  </form>
);

export default Form;
