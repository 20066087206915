import Image from 'next/image';
import React, { FC, forwardRef, useState } from 'react';

import clsx from 'clsx';

import eyeCloseIcon from 'public/static/lk-close-eye.svg';
import eyeIcon from 'public/static/lk-eye.svg';

import { ButtonType } from 'ui-kit/button/type';

import { Nullable } from 'types/common';

import { InputProps } from './type';

import styles from './input.module.scss';

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (componentProps: InputProps, ref) => {
    const {
      name = '',
      type = 'text',
      placeholder = '',
      label = '',
      onChange,
      onInput,
      onBlur,
      className = '',
      autoComplete,
      customMessage = false,
      fieldState,
      defaultValue,
      iconRight,
      inverted = false,
      readonly,
    }: InputProps = componentProps;
    // https://github.com/yannickcr/eslint-plugin-react/issues/3140 переименовала из-за недавнего бага

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleToggleShowPassword = () => {
      setShowPassword((prevState) => !prevState);
    };

    const isValid =
      (!fieldState?.error && fieldState?.isDirty) ||
      (defaultValue && !fieldState?.error);
    const isError = fieldState?.error;

    const isPassword = type === 'password';

    const iconPassword: JSX.Element = (
      <button
        type={ButtonType.button}
        className={styles.buttonIcon}
        onClick={handleToggleShowPassword}>
        <Image
          src={showPassword ? eyeIcon : eyeCloseIcon}
          unoptimized
          width={12}
          height={12}
          alt={showPassword ? 'Показать пароль' : 'Скрыть пароль'}
        />
      </button>
    );

    const getRightIcon = (): Nullable<JSX.Element> => {
      if (iconRight) {
        return iconRight;
      }

      if (isPassword) {
        return iconPassword;
      }

      return null;
    };

    const getTypeInput = (): 'text' | 'password' =>
      showPassword ? 'text' : 'password';

    const getInput = (): JSX.Element => (
      <input
        name={name}
        type={isPassword ? getTypeInput() : type}
        defaultValue={defaultValue}
        placeholder={placeholder}
        ref={ref}
        onChange={onChange}
        onInput={onInput}
        onBlur={onBlur}
        className={clsx(styles.input, {
          [styles.isError]: isError,
          [styles.valid]: isValid,
          [styles.inverted]: inverted,
          [styles.isIcon]: iconRight || isPassword,
        })}
        autoComplete={autoComplete ?? 'off'}
        readOnly={readonly}
      />
    );

    return (
      <label
        className={clsx(styles.label, {
          [className]: className,
        })}>
        {!customMessage && (
          <span
            className={clsx(styles.errorMessage, {
              [styles.errorMessageShow]: fieldState?.error,
            })}>
            {fieldState?.error?.message ?? ''}
          </span>
        )}
        <span className={styles.labelTitle}>{label}</span>
        {iconRight || isPassword ? (
          <div className={styles.wrapper}>
            {getInput()}
            <div
              className={clsx(styles.iconRight, styles.icon, {
                [styles.iconPadding]: !isPassword,
              })}>
              {getRightIcon()}
            </div>
          </div>
        ) : (
          getInput()
        )}
      </label>
    );
  },
);

Input.displayName = 'Input';

export default Input;
