import { LoginValuesType } from 'types/authorization';
import { RegistrationValuesType } from 'types/registration';
import { PASSWORD_RESTRICTIONS } from 'utils/schema';

export const REGISTRATION_ACTION_GROUP_TITLE = 'Вы здесь впервые?';

export const REGISTRATION_BTN_TEXT = 'Зарегистрироваться';

export const LOGIN_ACTION_GROUP_TITLE = 'У Вас уже есть аккаунт?';

export const LOGIN_BTN_TEXT = 'Войти';

export const LOGIN_ERRORS = {
  NOT_MATCH: 'Неверный пароль',
  EMAIL_INACTIVE: 'Пользователь еще не подтвержден',
  EMAIL_NOT_FOUND: 'Пользователь не найден',
  ORGANIZATION_NO_MATCH: 'Неверная организация',
};

export const TYPE_NAME_LOGIN = 'auth';

export const REGISTRATION_SERVER_ERRORS = {
  INVALID_EMAIL: 'Пользователь уже существует',
  INVALID_PASSWORD: `Должен быть более ${PASSWORD_RESTRICTIONS.MIN_LENGTH} и не менее ${PASSWORD_RESTRICTIONS.MAX_LENGTH} символов`,
};

export const initialRegistration: RegistrationValuesType = {
  email: '',
  firstName: '',
  secondName: '',
  birthDate: new Date().toISOString(),
  location: '',
  department: '',
  position: '',
  password: '',
  confirmPassword: '',
  termsOfService: false,
  privacyPolicy: false,
};

export const initialLogin: LoginValuesType = {
  email: '',
  password: '',
};
