import { Control, FieldError } from 'react-hook-form';
import { UserPassword } from './user';

export type DatePickerControl = Control<
  {
    [key: string]: string;
  },
  object
>;

export type RegistrationValuesType = {
  email: string;
  firstName: string;
  secondName: string;
  birthDate: string;
  location: string;
  department: string;
  position: string;
  password: string;
  confirmPassword: string;
  termsOfService: boolean;
  privacyPolicy: boolean;
};

export type ControlledElement = (
  control: DatePickerControl,
  error: FieldError,
) => React.ReactElement;

export enum RegistrationInputNamesEnum {
  firstName = 'firstName',
  secondName = 'secondName',
  email = 'email',
  birthDate = 'birthDate',
  location = 'location',
  department = 'department',
  position = 'position',
  password = 'password',
  confirmPassword = 'confirmPassword',
  termsOfService = 'termsOfService',
  privacyPolicy = 'privacyPolicy',
}

export type ResetPasswordType = Pick<
  RegistrationValuesType,
  'password' | 'confirmPassword'
>;

export interface UpdatePasswordValues extends UserPassword {
  repeat_password: string;
}
