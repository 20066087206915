import { Nullable } from './common';
import { OrganizationType } from './organization';

export interface User {
  id: string;
  firstName: string;
  secondName: string;
  patronymic: string;
  position: string;
  birthDate: string;
  email: string;
  phone: Nullable<string>;
  location: Nullable<string>;
  department: { name: string };
  organisation: OrganizationType;
  learningAccess: boolean;
  detailingAccess: boolean;
  role: {
    adminPanelAccess: boolean;
    developerModeAccess: boolean;
    name: string;
  };
}

export type UserInformation = Pick<
  User,
  | 'firstName'
  | 'secondName'
  | 'patronymic'
  | 'position'
  | 'location'
  | 'department'
>;

export enum UserPasswordEnum {
  oldPassword = 'oldPassword',
  newPassword = 'newPassword',
}

export type UserPassword = {
  [UserPasswordEnum.oldPassword]: string;
  [UserPasswordEnum.newPassword]: string;
};
